import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { API } from "aws-amplify";

import "./verify.css";

import { ValidationErrors } from "./ErrorsComponents/";
import VerifiedSuccess from "./VerifiedSuccess";

export default function Verify() {
  let [showText, setShowText] = useState(true);
  let [success, setSuccess] = useState(false);
  let [errorMessage, setErrorMessage] = useState();
  let { sub } = useParams();

  const showHideRef = useRef();

  const showHideText = () => {
    showHideRef.current = setInterval(() => {
      setShowText((showText) => !showText);
    }, 300);
  };

  useEffect(() => {
    let loader = document.querySelector(".circle-loader");
    let checkmark = document.querySelector(".checkmark");

    showHideText();

    API.post("verifyuser", "/verifyuser", { body: { sub } })
      .then((res) => {
        loader.classList.add("load-complete");
        checkmark.style.display = "block";

        clearInterval(showHideRef.current);
        setShowText(false);
        setSuccess(true);

        setTimeout(() => {
          return window.location.replace("https://www.tribel.com/login");
        }, 2000);
      })
      .catch((err) => {
        console.log("verifyuser error", err);
        clearInterval(showHideRef.current);
        let message = err.response.data.message;
        setErrorMessage(message);
        setSuccess(false);
      });
  }, []);

  if (errorMessage) return <ValidationErrors message={errorMessage} />;

  return (
    <div style={{ textAlign: "center" }}>
      <div className="circle-loader">
        <div className="checkmark draw"></div>
      </div>
      {!success ? (
        <p
          style={{
            transition: "visibility .2s",
            visibility: !showText ? "hidden" : "",
          }}
        >
          Verifiying.....
        </p>
      ) : (
        <VerifiedSuccess />
      )}
    </div>
  );
}
