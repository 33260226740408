/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:c026a5dc-5079-4f74-a5ac-a5f9107ab02e",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_hkU6gLAFy",
  aws_user_pools_web_client_id: "56bn6edujc666avr6dgb7ivhr9",
  oauth: {
    domain:
      "likerbackendsla5b44cc10-5b44cc10-prodlive.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://www.tribel.com/login/,tribel://callback/",
    redirectSignOut: "https://www.tribel.com/logout/,tribel://signout/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["FACEBOOK", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint:
    "https://lmwtjcb3tnhrjl6tbksnm74t44.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AWS_IAM",
  aws_cloud_logic_custom: [
    {
      name: "likerslamanageMysqlData",
      endpoint:
        "https://pwnvm5kfj5.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslalinkdata",
      endpoint:
        "https://17qz7k1bvd.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslagetBreaking",
      endpoint:
        "https://991wo7um9b.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslagettrending",
      endpoint:
        "https://kijjdr4g1h.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslagetfriends",
      endpoint:
        "https://q932faq218.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslaGetFollowingFeed",
      endpoint:
        "https://edwlvh8s4g.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslaOthersFeedData",
      endpoint:
        "https://ig9x53mcg8.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslaGetFilter",
      endpoint:
        "https://q7y8ibn334.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslaStarContributorCron",
      endpoint:
        "https://8bh409pkwf.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslaGetMetaData",
      endpoint:
        "https://21pbw4y5sl.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "likerslaGetMeta",
      endpoint:
        "https://gekrqwyvnd.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
    {
      name: "verifyuser",
      endpoint:
        "https://w0ojuocnb4.execute-api.us-east-1.amazonaws.com/prodlive",
      region: "us-east-1",
    },
  ],
  aws_user_files_s3_bucket: "likerslastaticbuckte45555-prodlive",
  aws_user_files_s3_bucket_region: "us-east-1",
};

export default awsmobile;
