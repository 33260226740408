import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./alreadyVerified.css";

export default function AreadyVerified() {
  let [counter, setCounter] = useState(5);
  const counterIntervel = useRef();

  const setCounterIntervel = () => {
    counterIntervel.current = setInterval(() => {
      counter = counter - 1;
      if (counter < 0) {
        clearInterval(counterIntervel.current);

        return window.location.replace("https://www.tribel.com/login");
      } else setCounter(counter);
    }, 1000);
  };

  useEffect(() => {
    setCounterIntervel();
  }, []);

  return (
    <div className="verified">
      <h2>Success!</h2>
      <h1>Your account has been already verified.</h1>
      <p className="desc">You may now sign in to Tribel.</p>
      <br />
      <p>
        You will be redirected to the{" "}
        <Link className="redirectLink" to="https://www.tribel.com/login">
          Tribel sign in
        </Link>{" "}
        page in <span className="counter"> {counter}</span> seconds
      </p>
    </div>
  );
}
