import React from "react";
import { AlreadyVerified, LinkExpired } from "./index";

import "./validationErrors.css";

export default function Invalid({ message }) {
  if (message === "invalid") {
    return (
      <div className="validation">
        {message === "invalid" && (
          <>
            <h1>400 Bad Request</h1>
            <p>Your request is invalid or the link is not a valid token!</p>
          </>
        )}
      </div>
    );
  } else if (message === "expired") {
    return <LinkExpired />;
  } else if (message === "verified") {
    return <AlreadyVerified />;
  } else {
    return (
      <div className="validation">
        <h1>500 Internal Server Error!</h1>
      </div>
    );
  }
}
