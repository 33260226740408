/* ©Asif Sorowar */

import { useEffect } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { FourOFour } from "./components/ErrorsComponents";
import Verify from "./components/Verify";
import { osName } from "react-device-detect";
import "./App.css";

function RedirectToTribel() {
  let mainUrl;
  if (window.screen.availWidth < 900) mainUrl = "https://m.tribel.com/";
  else mainUrl = "https://tribel.com/";

  useEffect(() => {
    return window.location.replace(mainUrl);
  }, []);

  return null;
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<RedirectToTribel />} />
        <Route path="/verify/:sub" exact element={<Verify />} />
        <Route path="/404" exact element={<FourOFour />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </div>
  );
}

export default App;
